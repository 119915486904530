.cbt-monitoring-page {
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.cbt-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #000;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.cbt-content {
  padding-top: 80px;
  max-width: 1400px;
  margin: 0 auto;
}

.border-sections {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.border-section {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.border-section h2 {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 15px;
  text-align: center;
  background: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.status-box {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  color: white;
}

.status-box.normal {
  background-color: #2196f3;
}

.status-box.bonded {
  background-color: #ffc107;
  color: #333;
}

.status-box.no-service {
  background-color: #757575;
}

.status-box h3 {
  font-size: 1rem;
  margin-bottom: 5px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.stat-item {
  text-align: center;
}

.stat-item label {
  display: block;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
}

.stat-item span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.chart-section {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 400px;
}

.chart-section h2 {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 1200px) {
  .border-sections {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .border-sections {
    grid-template-columns: 1fr;
  }
  
  .cbt-content {
    padding: 70px 15px 15px;
  }
}

.charts-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chart-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.chart-section {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 400px;
}

.chart-section h2 {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
  background: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  flex-shrink: 0;
}

.chart-section > div {
  flex: 1;
  position: relative;
  min-height: 0;
}

.chart-section canvas {
  margin-bottom: 40px;
}

@media (max-width: 1200px) {
  .chart-row {
    grid-template-columns: 1fr;
  }
  
  .chart-section {
    height: 400px;
  }
} 