/* 공통 스타일 */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* 헤더 스타일 */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  padding: 0 20px;
  z-index: 1000;
  border-bottom: 2px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
}

.logo-container {
  flex-shrink: 0;
  padding-top: 10px;
  flex: 1;
}

.logo {
  width: 150px;
}

/* 메뉴 스타일 */
.menu {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.menu li {
  margin-left: 15px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
}

.menu li:hover {
  text-decoration: underline;
}

  .menu.active {
    display: flex;
  }

.menu.open {
    display: flex;
  }


/* 햄버거 메뉴 스타일 (모바일용) */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  font-size: 24px;
  background: none;
  border: none;
  color: white; 
}

.hamburger div {
  width: 25px;
  height: 2px;
  background-color: white;
  margin: 4px;
}

/* 배너 스타일 */
.banner {
  margin-top: 40px;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('./../public/images/home_1page.png');
  /* background-image: url('/images/home_1page.png'); */
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
}

.banner-content h1 {
  font-size: 10vw;
  font-weight: 900;
  color: white;
  margin-bottom: 0;
}

.banner-content h2 {
  font-size: 6vw;
  font-weight: 900;
  color: orange;
  margin-top: -2vw;
}

/* About Us Section */
.about-us {
  padding: 60px 20px;
  background-color: white;
  max-width: 1200px;
  margin: 0 auto;
}

.about-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.about-left {
  flex: 1;
  max-width: 600px;
}

.about-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 900;
  color: black;
  margin-bottom: 20px;
}

.about-title .highlight {
  color: orange;
}

.about-text {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* 이미지 섹션 스타일 수정 */
.image-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  flex: 1;
  margin-top: 20px;
}

.image-section .image-box {
  width: 100%;
  position: relative;
  padding-top: 25%; /* 이미지 비율 조정 */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-section .image-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 45%; /* 이미지 위치 미세 조정 */
}

/* 반응형 디자인 */
@media (max-width: 1024px) {
  .about-content {
    flex-direction: column;
  }

  .about-left {
    max-width: 100%;
  }

  .image-section {
    width: 100%;
    margin-top: 40px;
  }

  .image-section .image-box {
    padding-top: 35%; /* 모바일에서의 이미지 비율 */
  }
}

@media (max-width: 768px) {
  .about-us {
    padding: 40px 20px;
  }

  .about-title {
    font-size: 2rem;
  }

  .about-text {
    font-size: 1rem;
  }

  .image-section .image-box {
    padding-top: 45%; /* 더 작은 화면에서의 이미지 비율 */
  }
}

/* Vision 섹션 */
.vision {
  background-color: #000;
  color: white;
  padding: 60px 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: visible;
}

.vision-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.vision-left {
  flex: 1;
  max-width: 60%;
  text-align: left;
}

.vision-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 900;
  text-align: left;
}

.vision-title span {
  color: orange;
}

.vision-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.vision-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}

.vision-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 40%;
  margin-top: 120px;
}

.vision-images .image-box {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.vision-images .image-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  max-height: 300px;
}

/* 반응형 설정 */
@media (max-width: 1300px) {
  .vision-content {
    flex-direction: column;
  }

  .vision-left {
    max-width: 100%;
    margin-right: 0;
  }

  .vision-images {
    max-width: 100%;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .vision {
    padding: 40px 20px;
  }

  .vision-title {
    font-size: 2rem;
  }

  .vision-list li {
    font-size: 0.9rem;
  }
}

/* Mission Section */
.mission {
  background-color: white;
  color: black;
  padding: 60px 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: visible;
}

.mission-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.mission-left {
  flex: 1;
  max-width: 60%;
  text-align: left;
}

.mission-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 900;
  color: black;
  margin-bottom: 20px;
}

.mission-title span {
  color: orange;
}

/* Mission 리스트 스타일 */
.mission-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.mission-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}

.mission-list li div:last-child {
  flex: 1;
}

.mission-list li h3 {
  margin: 0 0 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.4;
  color: black;
}

.mission-list li p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

/* Mission 이미지 섹션 */
.mission-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 40%;
  margin-top: 120px;
}

.mission-images .image-box {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.mission-images .image-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  max-height: 300px;
}

/* 반응형 설정 */
@media (max-width: 1300px) {
  .mission-content {
    flex-direction: column;
  }

  .mission-left {
    max-width: 100%;
    margin-right: 0;
  }

  .mission-images {
    max-width: 100%;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .mission {
    padding: 40px 20px;
  }

  .mission-title {
    font-size: 2rem;
  }

  .mission-list li {
    font-size: 0.9rem;
  }
}

/* History 섹션 */
.history {
  background-color: white; /* 배경색 */
  color: black; /* 텍스트 색상 */
  padding: 60px 40px; /* 패딩 */
  display: flex;
  flex-direction: column; /* 세로로 배치 */
  align-items: center; /* 중앙 정렬 */
  box-sizing: border-box;
}

.history-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem); /* 반응형 타이틀 크기 */
  font-weight: 900;
  color: black; /* 타이틀 색상 */
  margin-bottom: 20px; /* 타이틀과 내 사이 여백 */
  text-align: center; /* 타이틀 중앙 정렬 */
}

.history-title span {
  color: orange; /* History 텍스트는 오렌지색 */
}

.history-content {
  display: flex;
  justify-content: space-between; /* 타임라인 박스 간격 */
  width: 100%;
  max-width: 1200px; /* 섹션 최대 너비 */
  margin-bottom: 40px; /* 하단 여백 */
}

.history-box {
  background-color: white; /* 박스 배경 */
  border: 1px solid #ddd; /* 경계선 */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* 그림자 */
  padding: 20px;
  margin: 10px;
  flex: 1; /* 각 박스의 동일한 너비 */
  max-width: 250px; /* 박스 최대 너비 */
  text-align: center;
}

.history-box h3 {
  font-size: 1.2rem;
  color: orange; /* 년도 색상 */
  margin-bottom: 10px;
}

.history-box p {
  font-size: 0.9rem;
  color: #333; /* 텍스트 색상 */
  line-height: 1.5;
}

.history-images {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-top: 40px; /* 상단 여백 */
}

.history-image-box {
  width: 48%; /* 두 이미지가 나란히 배치되도록 설정 */
  height: auto;
}

.history-image-box img {
  width: 100%;
  height: auto;
  object-fit: cover; /* 이미지가 박스를 넘지 않도록 설정 */
}

/* 반응형 설정 */
@media (max-width: 768px) {
  .history-content {
    flex-direction: column; /* 작은 화면에서 세로 배치 */
  }

  .history-box {
    max-width: 100%; /* 박스 너비를 100%로 설정 */
    margin-bottom: 20px; /* 박스 사이 여백 */
  }

  .history-images {
    flex-direction: column; /* 이미지도 세로 배치 */
  }

  .history-image-box {
    width: 100%; /* 이미지 너비를 100%로 설정 */
    margin-bottom: 20px;
  }

}


  /* Service 섹션 */

.service-card.logistics h3 {
  color: black;
  text-decoration: underline; /* 밑줄 */
  text-transform: uppercase; /* 대문자로 변경 */
  font-weight: bold; /* 굵은 글씨 */
}

.service-card.devops h3 {
  color: black;
  font-weight: bold; /* 굵은 글씨 */
  text-decoration: underline; /* 밑줄 */
  letter-spacing: 1px; /* 자간 조정 */
}

.service-card.trade-agency h3 {
  color: black; /* 라임 그린 */
  font-weight: bold; /* 굵은 글씨 */
  text-decoration: underline; /* 밑줄 */
  
}

/* Quote 스타일 */
.quote {
  font-size: 1.5rem; /* 글자 크기 증가 */
  font-weight: 700;
  font-style: italic; /* 이탤릭체 적용 */
  color: #555; /* 부드러운 회색 */
  margin: 20px 0;
  padding: 10px;
  border-left: 4px solid orange; /* 왼쪽에 오렌지색 테두리 추가 */
  text-align: center;
  line-height: 1.8; /* 줄 간격 조정 */
}

/* Quote 속 인용 부분 강조 */
.quote em {
  font-weight: bold; /* 인용 부분을 굵게 */
  color: #ff4500; /* 오렌지색으로 강조 */
}

/* 서비스 설명 텍스트 스타일 */
.service-description {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333; /* 부드러운 검정색 */
  line-height: 1.7;
  margin-bottom: 40px;
  padding: 10px 20px;
  background-color: #f9f9f9; /* 배경색 추가 */
  border-radius: 10px; /* 둥근 테두리 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* 살짝 그림자 추가 */
}
/* 빈 박스 스타일 */
.spacer-box {
  height: 80px; /* 빈 박스의 높이 조정 (예: 40px) */
  width: 100%; /* 가로로 꽉 차게 설정 */
}


/* 공통 스타일 적용 */
.service-card h3 {
  font-size: 1.8rem; /* 조금 더 큰 글자 크기 */
  margin-top: 15px;
  margin-bottom: 10px;
  transition: color 0.3s ease; /* 마우스 오버 시 색상이 부드럽게 변화 */
}

.service-card h3:hover {
  color: orange; /* 마우스 오버 시 오렌지 색으로 변 */
}

.service {
  padding: 60px 40px;
  background-color: white;
  text-align: center;
  max-width: 1200px; /* 섹션의 최대 너비 설정 */
  margin: 0 auto; /* 가운데 정렬 */
}

.service-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 900;
  color: black;
  margin-bottom: 80px;
  text-align: center; /* 타이틀 중앙 정렬 */
}

.service-title span {
  color: orange;
}

.quote {
  text-align: center;
  font-style: italic;
  margin-bottom: 80px;
  font-size: 1.2rem;
}

.service-description {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 40px;
  max-width: 800px; /* 본문 텍스트의 최대 너비 설정 */
  margin: 0 auto; /* 본문을 가운데 정렬 */
}

.service-cards {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap; /* 화면 크기에 따라 줄바꿈 허용 */
  max-width: 1200px; /* 카드들이 넓어지지 않도록 섹션 최대 너비 설정 */
  margin: 0 auto; /* 카드들을 가운데 정렬 */
}

.service-card {
  background-color: orange;
  color: black;
  padding: 20px;
  margin: 20px;
  flex-basis: 30%; /* 화면 크기에 따라 카드의 크기를 자동 조절 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 250px; /* 카드의 최소 너비 설정 */
  max-width: 300px; /* 카드의 최대 너비 설정 */
}

.service-card h3 {
  font-size: 1.5rem;
  margin-top: 15px;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 10px;
}

.service-icon {
  width: 50px;
  height: 50px;
}

/* 반응형 설정 */
@media (max-width: 768px) {
  .service-cards {
    flex-direction: column;
  }

  .service-card {
    width: 80%;
    margin: 10px auto;
  }
}

/* Team Section */
.team-section {
  padding: 60px 20px;
  background-color: white;
  text-align: center;
}

.team-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 900;
  color: black;
  margin-bottom: 40px;
}

.team-title span {
  color: orange;
}

.spacer-box1 {
  height: 130px; /* 빈 박스의 높이 조정 (예: 40px) */
  width: 100%; /* 가로로 꽉 차게 설정 */
}

.team-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* 줄바꿈 허용 */
  gap: 20px; /* 카드 사이에 간격 추가 */
  max-width: 1200px;
  margin: 0 auto;
}

.team-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  width: 250px; /* 카드의 너비를 동일하게 유지 */
  flex-grow: 1; /* 카드가 flexbox 레이아웃에 맞춰 동일한 크기로 확장됨 */
}

.team-image {
  width: 100%;
  height: auto; /* 이미지가 카드의 너비에 맞춰 자동으로 크기 조정 */
  object-fit: cover; /* 이미지를 박스에 맞게 자름 */
}

.team-location {
  padding: 10px;
  background-color: orange;
  color: white;
  font-weight: bold;
  text-transform: uppercase; /* 대문자로 변환 */
}

/* 반응형 설정 */
@media (max-width: 768px) {
  .team-cards {
    flex-direction: column;
  }

  .team-card {
    width: 80%;
    margin: 10px auto;
  }
}

/* DevOps Section */
.devops-section {
  padding: 60px 20px;
  background-color: white;
  max-width: 1200px;
  margin: 0 auto;
}

.devops-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.devops-left {
  flex: 1;
  max-width: 600px;
}

.devops-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 900;
  color: black;
  margin-bottom: 20px;
}

.devops-title span {
  color: orange;
}

.devops-text {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.8;
  margin-bottom: 20px;
}

.devops-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.stat-box {
  background-color: orange;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  flex-basis: 25%;
}

.stat-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.stat-number {
  font-size: 1.5rem;
  font-weight: bold;
}

.stat-label {
  font-size: 1rem;
}

.devops-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  flex: 1;
  margin-top: 140px;
}

.devops-image {
  width: 100%;
  height: auto;
  border: 1px solid orange;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 반응형 설정 */
@media (max-width: 768px) {
  .devops-content {
    flex-direction: column;
  }

  .devops-right {
    grid-template-columns: 1fr;
  }
}

/* Contact Section */
.contact-section {
  
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 60px 20px;
  background-color: white;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 900;
  margin-left: 20px;
  color: white;
  margin-bottom: 20px;
}

.contact-title span {
  color: orange;
}

/* Contact Left Section */
.contact-left {
  background-color: black;
  flex: 0.6; /* contact-left의 비율을 더 높여 넓어지도록 설정 */
  max-width: 600px;
}

.contact-details {
  margin-bottom: 30px;
  color: white;
  margin-left: 20px;
}

.detail-box {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.contact-qr {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 22px;
  margin-bottom: 22px;
}

.qr-image {
  width: 100px;
  height: 100px;
}

.contact-qr p {
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

/* Contact Right Section */
.contact-right {
  flex: 0.4; /* contact-right의 비율을 줄여 더 작은 비율로 설정 */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-image.large {
  width: 100%;
  height: 600px;
  object-fit: contain; /* 변경: 이미지가 짤리지 않도록 설정 */
  border-radius: 10px;
  
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
  }

  .contact-right {
    flex-direction: column;
  }

  .contact-image.large,
  .contact-image.small {
    width: 100%;
  }
}

/* 반응형 설정 추가 */
@media (max-width: 768px) {
  .hamburger {
    display: flex; /* 모바일에서 햄버거 메뉴 표시 */
  }

  .menu {
    display: none; /* 기본적으로 메뉴 숨김 */
    position: absolute;
    top: 40px; /* 헤더 높이만큼 아래로 */
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: black;
    padding: 20px;
    width: 100%;
  }

  .menu.active {
    display: flex; /* active 클래스가 있을 때만 메뉴 표시 */
  }

  .menu li {
    margin: 10px 0;
    text-align: center;
  }
}

/* 원 모양 숫자 스타일 추가 */
.circle {
  width: 35px;
  height: 35px;
  min-width: 35px;
  background-color: orange;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 3px; /* 제목과 수직 정렬을 위해 상단 여백 조정 */
}

/* 텍스트 컨테이너 스타일 수정 */
.vision-list li div:last-child {
  flex: 1;
}

.vision-list li h3 {
  margin: 0 0 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.4;
}

.vision-list li p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #ffffff;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.cocodot-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #FFA500;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-size: 1.1rem;
}

.cocodot-button:hover {
  background-color: #FF8C00;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
